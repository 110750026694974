$(window).on('load', function() {

  var title = $('.mv-title span');
  var interval = 140;
  var message = $('.mv-message');
  var logo = $('.mv-message-logo');

  function loop(){
    // Main visual

    var titleDisplay = $.when(
      title.each(function(i) {
        title.eq(i).delay(i * interval).animate({
          'opacity': '1'
        }, 900);
      })
    );

    titleDisplay.done(function() {
      message.animate({
        'opacity': '1'
      }, 10, function () {
        $(this).addClass('mv-message-js-blur');
      });
      title.delay(4000).animate({
        'opacity': '0'
      }, 300);
      message.delay(4000).animate({
        'opacity': '0'
      }, 300).removeClass('mv-message-js-blur');
      logo.delay(6000).animate({
        'opacity': '1'
      }, 600, function () {
        $(this).delay(2000).animate({
          'opacity': '0'
        }, 1000);
      });
    });
    setTimeout(loop, 13500);
  }
  loop();
});

