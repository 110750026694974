// MV Background

function Particle() { };

Particle.prototype.x = 0;
Particle.prototype.y = 0;
Particle.prototype.velX = 0;
Particle.prototype.velY = 0;
Particle.prototype.rad = 0;
Particle.prototype.w = 0;
Particle.prototype.time = 0;
Particle.prototype.zrot = 0;
Particle.prototype.zrotW = 0;
Particle.prototype.scale = 1;

var stageX = 445;
var stageY = 445;

var mainCanvasContext;

var particles = new Array();

var mouseX = 0;
var mouseY = 0;

var windVelX = 0;
var windVelY = 0;

var image = new Image();
image.src = "../images/img_flower01.png";

function initParticle(initP) {
  initP.w = 0.4 * (0.5 - Math.random());
  initP.rad = Math.PI * Math.random();
  initP.velY = Math.random() * 3 + 7;
  initP.velX = (0.5 - Math.random()) * 10;
  initP.zrot = Math.PI * Math.random();
  initP.zrotW = (0.5 - Math.random()) / 10;
  initP.scale = (1.1 - Math.random()) * 0.9;
  initP.time = 0;
}

function init(quantity) {
  for (var i = 0; i < quantity; i++) {
    var p = new Particle();
    initParticle(p);
    p.y = (1.1 - 1.4 * Math.random()) * stageY;
    p.x = Math.random() * stageX;

    particles.push(p);

  }
};


function sortParticle(p1, p2) {
  return (p1.scale - p2.scale);
}

function timerFunc() {
  mainCanvasContext.setTransform(1, 0, 0, 1, 0, 0);
  mainCanvasContext.fillRect(0, 0, stageX, stageY);
  mainCanvasContext.clearRect(0, 0, stageX, stageY);
  particles.sort(sortParticle);

  for (var i = 0; i < particles.length; i++) {
    var p = particles[i];
    var rad = p.rad;
    mainCanvasContext.setTransform(Math.cos(rad), Math.sin(rad), -Math.sin(rad), Math.cos(rad), p.x + image.width * 0.8, p.y + image.height * 0.8);
    var s = Math.sin(p.zrot);
    var c = Math.cos(p.zrot);
    mainCanvasContext.scale(p.scale * s, p.scale * c);
    mainCanvasContext.drawImage(image,
      0, 0,
      image.width, image.height,
      -image.width * 0.8, -image.height * 0.8,
      image.width, image.height,
    );

    p.time++;
    p.x += (p.velX + windVelX) * (p.scale / 3);
    p.y += (p.velY + windVelY) * (Math.abs(p.scale) / 3);
    p.rad += p.w;
    p.zrot += p.zrotW;
    if (p.rad > Math.PI) {
      p.rad = -Math.PI;
    } else if (p.rad < -Math.PI) {
      p.rad = Math.PI;
    }
    var ts = image.width * Math.abs(p.scale);
    if (p.y > stageY + ts || p.x < -ts || p.x > ts + stageX) {
      initParticle(p);
      ts = image.width * Math.abs(p.scale)
      if (p.y > stageY + ts) {
        p.y = -ts;
        p.x = Math.random() * stageX;
      } else {
        if ((p.velX + windVelX) * (p.scale / 3) > 0) {
          p.x = -ts;
        } else {
          p.x = stageX + ts;
        }
      }


    }


  }
}


window.onload = function () {
  if (this.document.getElementById('js-sakura') != null) {
    var timer = setInterval(timerFunc, 30);
    var canvas = window.document.getElementById("js-sakura");
    mainCanvasContext = canvas.getContext("2d");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight - 50;
    stageX = canvas.width;
    stageY = canvas.height;
    init(140);
    canvas.onmousemove = function (e) {
      mouseX = e.clientX;
      mouseY = e.clientY;
      windVelX = (0.5 - mouseX / stageX) * 35;
      windVelY = (0.6 - mouseY / stageY) * 15;
    };
  }

};
