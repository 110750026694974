import '../styles/main.scss';
import 'bootstrap';
import './_slick';
import './_jquery.heightLine';
import './_sakura';
import './_displayEffects';
import './_fadein';
import './_jquery.color';
import './_jquery.drawsvg.min';
import './_drawMessage';

$(function () {

  // heightLine settings
  $('.recent__item-title').heightLine();

  // SNS tab contents(for mobile)
  var tabBtn = $('.js-tab-button');
  var tabItem = $('.js-tab-item');
  if (window.matchMedia("(max-width: 767px)").matches) {
    tabItem.hide();
    tabItem.eq(0).show();
    tabItem.eq(0).addClass('js-active');

    tabBtn.each(function () {
      $(this).on('click', function () {
        var index = tabBtn.index(this);
        tabBtn.removeClass('js-active');
        $(this).addClass('js-active');
        tabItem.hide();
        tabItem.eq(index).fadeIn();
      });
    });
  }

  // Recommend layout(for desktop)
  if (window.matchMedia("(min-width: 768px)").matches) {
    do {
      $('.recommend__slick').children('.recommend__item:lt(2)').wrapAll('<div class="js-recommend-wrap"></div>')
    } while ($('.recommend__slick').children('.recommend__item').length);

    $('.recommend__title').prependTo('.recommend__slick');
    $('.recommend__slick').addClass('d-md-flex');
    $('.recommend .container').removeClass('d-md-flex');
  }

  // smooth scroll
  $('a[href^="#"]').click(function () {
    var speed = 400;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $('body,html').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });

  // Mobile menu
  var headerMenu = $('.header__menu');
  var headerBtn = $('.header__icon');
  var menuItem = $('.header__menu-item');

  var state = false;
  var scrollpos;

  headerBtn.on('click', function () {
    menuItem.css('opacity', 0);
    $(this).toggleClass('js-active');
    if ($(this).hasClass('js-active')) {
      headerMenu.animate(
        {
          left: '0'
        },
        {
          duration: '80',
          easing: "linear"
        }
      );
      menuItem.each(function (i) {
        $(this).delay(180 * i).animate({
          opacity: 1
        },
          {
            duration: '100',
            easing: "linear"
          }
        );
      });
    } else {
      headerMenu.animate(
        {
          left: '-100%'
        }
      )
    }
    if (state == false) {
      scrollpos = $(window).scrollTop();
      $('body').addClass('js-fixed').css({ 'top': -scrollpos });
      state = true;
    } else {
      $('body').removeClass('js-fixed').css({ 'top': 0 });
      window.scrollTo(0, scrollpos);
      state = false;
    }
  });

  $('.header__menu-item-link').on('click', function () {
    $('body').removeClass('js-fixed');
    headerBtn.removeClass('js-active');
    headerMenu.animate(
      {
        left: '-100%'
      }
    )
    $(window).off('.noScroll');
  });

});
