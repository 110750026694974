import 'slick-carousel'

$(function() {
  if (window.matchMedia("(max-width: 767px)").matches) {
    $('.recent__slick').not('.slick-initialized').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      infinite: false,
      speed: 300,
      arrows: true
    });
    $('.recommend__slick').not('.slick-initialized').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      infinite: false,
      speed: 300,
      arrows: true
    });
  }

  $('.sns_instagram__slick').not('.slick-initialized').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    speed: 300,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  });
});
