function isContentsView(elm, distanceY) {
  var viewTop = $(window).scrollTop();
  var viewBottom = viewTop + $(window).height();

  var elmTop = $(elm).offset().top-distanceY;
  var elmBottom = elmTop + $(elm).height();

  return (( elmBottom <= viewBottom ) && (elmTop >= viewTop ));
}

var recent = $('.recent');
var recommend = $('.recommend');
var sns = $('.sns__tab-contents');
var instagram = $('.sns_instagram');
var followus = $('.followus');

var interval = 300;


if (window.matchMedia("(min-width: 767px)").matches) {
  var userAgent = window.navigator.userAgent.toLowerCase();
  if(userAgent.indexOf('trident') != -1 || userAgent.indexOf('edge') != -1) {
    $('.recent, .recommend').css({
      'opacity': '0'
    });
  } else {
    $('.recent, .recent h2, .recent__item, .recommend__title, .recommend__item').css({
      'opacity': '0'
    });
    $('.recent h2').css({
      'margin-top': '30px'
    });
    recommend.css({
      'opacity': '0'
    });
    $('.recommend__slick').css({
      'backgroundColor': 'transpaent'
    });
  }
  $('.sns__tab-item, .sns_instagram, .followus, .followus__icons').css({
    'opacity': '0'
  });
  $('.sns__tab-item, .sns_instagram').css({
    'margin-top': '30px'
  });


  followus.css({
    'backgroundColor': '#fff'
  });


  $(window).scroll(function() {
    // Recent

    if(isContentsView(recent, 500) == true) {
      if(userAgent.indexOf('trident') != -1 || userAgent.indexOf('edge') != -1) {
        recent.animate({
          'opacity': '1'
        }, 300);
      } else {
        recent.animate({
          'opacity': '1'
        }, 200);
        $('.recent h2').animate({
          'opacity': '1',
          'margin-top': '0'
        }, 500, function() {
          var recentItem = $('.recent__item');
          recentItem.each(function(i) {
            recentItem.eq(i).delay(i * 300).animate({
              'opacity': '1'
            }, 400);
          });
        });
      }
    }

    // Recommend
    if(isContentsView(recommend, 400) == true) {
      if(userAgent.indexOf('trident') != -1 || userAgent.indexOf('edge') != -1) {
        recommend.animate({
          'opacity': '1'
        }, 300);
      } else {
        recommend.animate({
          'opacity': '1'
        }, 300, function() {
          $('.recommend__slick').animate({
            'backgroundColor': '#fcf7d7'
          }, 100);
          $('.recommend__title').animate({
            'opacity': '1'
          }, 300, function() {
            var product = $('.recommend__item');
            product.each(function(i) {
              product.eq(i).delay(i * interval).animate({
                'opacity': '1'
              }, 400);
            });
          });
        });
      }
    }

    // SNS
    if(isContentsView(sns, 200) == true) {
      var snsTabItem = $('.sns__tab-item');
      snsTabItem.each(function(i) {
        snsTabItem.eq(i).delay(i * 400).animate({
          'opacity': '1',
          'marginTop': '0'
        }, 400);
      });
    }

    // instagram
    if(isContentsView(instagram, 100) == true) {
      instagram.animate({
        'opacity': '1',
        'marginTop': '0'
      }, 400);
    }

    // follow us
    if(isContentsView(followus, 0) == true) {
      followus.animate({
        'backgroundColor': '#bfb381',
        'opacity': '1'
      }, 400, function() {
        $('.followus__icons').animate({
          'opacity': '1'
        }, 400);
      });
    }

  });
}

