$(window).on('load', function () {
  // svg message
  var messageLogo = $('.mv-svg-message-logo-image');
  var gradBackground = $('.mv-svg-logo');
  var gradBackGroundLogo = $('.mv-svg-logo-image');

  function mvLoop() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    if(userAgent.indexOf('trident') != -1 || userAgent.indexOf('edge') != -1) {
      $('.mv-svg-message-for-ie').animate({
        'opacity': '1'
      }, 2000);

      messageLogo.delay(1500).animate({
        'opacity': '1'
      }, 300, function() {
        $('.mv-svg-message-for-ie').delay(4000).animate({
          'opacity': '0'
        }, 1000);
        messageLogo.delay(4000).animate({
          'opacity': '0'
        }, 1000, function() {
          gradBackground.delay(500).animate({
            'opacity': '1'
          }, 1000, function() {
            gradBackGroundLogo.delay(1000).animate({
              'opacity': '1'
            }, 1000, function() {
              gradBackground.delay(3000).animate({
                'opacity': '0'
              }, 1000);
              gradBackGroundLogo.delay(3000).animate({
                'opacity': '0'
              },1000);
            });
          });
        });
      });
      setTimeout(mvLoop, 15000);
    } else {
      $('.mv-svg-message-for-ie').css({
        'display': 'none'
      });
      $('#mv-svg-message-svg').css({
        'opacity': '1',
        'display': 'block'
      });
      var $svg = $('#mv-svg-message-svg').drawsvg({
        duration: 300,
        easing: 'linear'
      });
      $svg.drawsvg('animate');

      messageLogo.delay(6000).animate({
        'opacity': '1'
      }, 1000, function() {
        $svg.delay(900).animate({
          'opacity': '0'
        }, 800);
        messageLogo.delay(900).animate({
          'opacity': '0'
        }, 800, function() {
          gradBackground.delay(300).animate({
            'opacity': '1'
          }, 1000, function() {
            gradBackGroundLogo.delay(500).animate({
              'opacity': '1'
            }, 1000, function() {
              gradBackground.delay(2000).animate({
                'opacity': '0'
              }, 1000);
              gradBackGroundLogo.delay(2000).animate({
                'opacity': '0'
              },1000);
            });
          });
        });
      });
      setTimeout(mvLoop, 15400);
    }
  }
  mvLoop();

});
